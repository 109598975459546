export const de = {
  "save": "Speichern",
  "update": "Aktualisieren",
  "accept": "Annehmen",
  "cancel": "Abbrechen",
  "close": "Schließen",
  "add": "Hinzufügen",
  "check": "Prüfen",
  "buttons": "Buttons",
  "calendar": "Calendar",
  "components": "Components",
  "certificate": "Zertifikat",
  "certificates": "Zertifikate",
  "certification": "Zertifizierung",
  "certifications": "Zertifizierungen",
  "valid-certificate": "Gültiges Zertifikat",
  "date": "Datum",
  "valid-thru": "Gültig bis",
  "dashboard": "Startseite",
  "dtables": "Data Tables",
  "eforms": "Extended Forms",
  "error": "Fehlerseite",
  "forms": "Formulare",
  "maps": "Maps",
  "notifications": "Benachrichtigungen",
  "pages": "Pages",
  "plan": "Choose Plan",
  "pricing": "Pricing",
  "my-profile": "Mein Profil",
  "register-headline": "Registrierung",
  "register": "Registrieren",
  "search": "Suche",
  "settings": "Einstellungen",
  "tables": "Tabellen",
  "tabs": "Tabs",
  "myeval": "EvalCard",
  "eval-id": "Eval-ID",
  "id": "ID",
  "timeline": "Timeline",
  "typography": "Typographie",
  "admin": "Administrator",
  "user": "Benutzer",
  "users": "Benutzer",
  "username": "Benutzername",
  "username-or-email": "Benutzername oder Email",
  "user-profile": "Benutzerprofil",
  "company-name": "Firmenname",
  "company-logo": "Firmenlogo",
  "company-profile": "Firmenprofil",
  "company-profile-admin": "Firmenprofil",
  "company-invitation": "Firmeneinladung",
  "invitation-sent": "Einladung versendet",
  "new-password": "Neues Passwort",
  "generate-new-password": "Neues Passwort generieren",
  "current-password": "Derzeitiges Passwort",
  "repeat-password": "Passwort wiederholen",
  "password-successfully-changed": "Ihr Passwort wurde erfolgreich geändert",
  "password-reset": "Passwort zurücksetzen",
  "edit-user-profile": "Benutzerprofil editieren",
  "edit-user-profile-admin": "Benutzerprofil editieren",
  "system": "System",
  "vforms": "Validation Forms",
  "widgets": "Widgets",
  "wizard": "Wizard",
  "logout": "Ausloggen",
  "login": "Einloggen",
  "companies": "Firmen",
  "my-company": "Meine Firma",
  "leave-company": "Firma verlassen",
  "delete-company": "Firma löschen",
  "company": "Firma",
  "uid": "UID/Steuernummer",
  "employees": "Angestellte",
  "employee": "Mitarbeiter/in",
  "companyadmin": "Admin",
  "evalclient": "EVAL Beauftragter",
  "coworker": "Mitarbeiter",
  "coworker-profile": "Mitarbeiterprofil",
  "trainer": "Trainer",
  "authorityAdmin": "Admin",
  "trainerRO": "Trainer (Nur Lesen)",
  "authorityAdminRO": "Admin (Nur Lesen)",
  "protocols": "Prüfberichte",
  "all-protocols": "Alle Prüfberichte",
  "report": "Prüfbericht",
  "new-report": "Neuer Prüfbericht",
  "reports": "Prüfberichte",
  "all-reports": "Alle Prüfberichte",
  "report-from": "Prüfbericht vom",
  "report-details": "Prüfbericht Details",
  "data": "Daten",
  "info": "Infos",
  "attachment": "Anhang",
  "contact": "Kontakt",
  "continue": "Weiter",
  "summary": "Zusammenfassung",
  "vin": "VIN",
  "licence-plate": "Kennzeichen",
  "owner": "Besitzer",
  "vehicles": "Fahrzeuge",
  "vehicle": "Fahrzeug",
  "vehicle-details": "Fahrzeug Details",
  "companyVehicle": "Firmen-Fahrzeug",
  "valid": "In Ordnung",
  "invalid": "Nicht in Ordnung",
  "tester": "Prüfer",
  "vehicles-admin": "Fahrzeuge",
  "create-company-vehicle": "Fahrzeug anlegen",
  "select-vehicle": "Fahrzeugauswahl",
  "my-vehicles": "Meine Fahrzeuge",
  "my-vehicle": "Mein Fahrzeug",
  "new-vehicle": "Neues Fahrzeug",
  "manufacturer": "Hersteller",
  "no-manufacturer": "(Kein Hersteller)",
  "no-vehicle": "(Kein Fahrzeug)",
  "vehicle-manufacturer": "Fahrzeughersteller",
  "create-vehicle": "Fahrzeug anlegen",
  "create-vehicle-manufacturer": "Fahrzeughersteller anlegen",
  "construction-year": "Baujahr",
  "change-password": "Passwort ändern",
  "roles": "Berechtigungen",
  "actions": "Aktionen",
  "action": "Aktion",
  "profile": "Profil",
  "email": "E-Mail",
  "basic": "Basis",
  "rights": "Rechte",
  "details": "Details",
  "information": "Informationen",
  "name": "Name",
  "namename": "Bezeichnung",
  "firstname": "Vorname",
  "lastname": "Nachname",
  "fullname": "Voller Name",
  "password": "Passwort",
  "street": "Strasse",
  "number": "Nummer",
  "city": "Stadt",
  "country": "Land",
  "forgot-password": "Passwort vergessen?",
  "recover-password": "Passwort zurücksetzen",
  "back": "Zurück",
  "back-to-login": "Zurück zum Login",
  "go-to-login": "Zum Login",
  "choose-picture": "Bild auswählen",
  "choose-logo": "Logo auswählen",
  "send-invitation": "Einladung senden",
  "zipcode": "PLZ",
  "previous": "Zurück",
  "abort": "Abbrechen",
  "i-understand": "Verstanden",
  "YES": "Ja",
  "no": "Nein",
  "yes": "Ja",
  "inputs": "Eingaben",
  "user-activated": "Aktiviert",
  "user-not-activated": "Benutzer ist noch nicht aktiviert",
  "user-did-not-receive-activation-email": "Benutzer hat noch keine Aktivierungs-Email erhalten",
  "allow-coworkers-to-show-on-profile": "Auf dem Benutzer-Profil der Mitarbeiter anzeigen:",
  "resend-activation-email": "Aktivierungslink anfordern",
  "coworker-education-status": "Mitarbeiter Schulungsstatus",
  "card": "Visitenkarte",
  "certification-authority": "Zertifizierungsstelle",
  "certification-authority-admin": "Zertifizierungsstelle",
  "certification-authorities": "Zertifizierungsstellen",
  "delete-certification-authority": "Zulassungsstelle löschen",
  "certificate-templates": "Zertifikatsvorlagen",
  "certificate-template": "Zertifikats Vorlage",
  "creating-certificates": "Zertifikate werden erstellt...",
  "issue-certificate": "Zertifikat ausstellen",
  "issue-date": "Ausstellungsdatum",
  "due-date": "Ablaufdatum",
  "issued-on": "Ausgestellt am",
  "issued-by": "Ausgestellt von",
  "author": "Aussteller",
  "certificate-name": "Zertifikatsname",
  "certificate-number": "Zertifikatsnummer",
  "born-on": "geboren am",
  "mileage": "Kilometerstand",
  "public": "Öffentlich",
  "back-to-the-app": "Zurück zur App",
  "can-create-reports": "Prüfberichte möglich",
  "valid-for": "Gültig für (Jahre)",
  "pdf-template": "PDF Vorlage",
  "pdf-certificate": "Eigenes PDF Zertifikat",
  "show-pdf-certificate": "Zertifikat als PDF",
  "standards": "Standards (HTML)",
  "text-block": "Text nach Standards (HTML)",
  "digital-signature": "Digitale Unterschrift",
  "eval1": "EVAL-1",
  "eval2": "EVAL-2",
  "eval3": "EVAL-3",
  "others": "Andere",
  "level": "Stufe",
  "type": "Typ",
  "training-data": "Schulungsunterlagen",
  "training-info": "Schulungsinformationen",
  "generated-password": "Generiertes Passwort",
  "invitation-email": "Einladungs Email",
  "document": "Dokument",
  "documents": "Dokumente",
  "active": "aktiv",
  "inactive": "inaktiv",
  "all": "ALLE",
  "all-sort": "Alle/Sortiern",
  "created-on": "Erstellt am",
  "result": "Ergebnis",
  "results": "Ergebnisse",
  "try-again": "Erneut Versuchen",
  "status": "Status",
  "sn": "SN",
  "something-went-wrong": "Etwas ist schief gelaufen",
  "info-signs": "Hinweisschilder",
  "danger": "Achtung",
  "hazardous-voltage": "Gefährliche Spannung",
  "no-access-1": "Unbefugten",
  "no-access-2": "Zutritt verboten",
  "access-only-by": "Zutritt nur durch",
  "company-logo-is-missing": "Firmenlogo fehlt",
  "download": "Herunterladen",
  "access-denied": "Zutritt verboten",
  "dont-switch-on": "Nicht einschalten",
  "hv-system": "HV-System",
  "disconnected": "Freigeschalten",
  "disconnected-by": "Freigeschalten durch",
  "works-in-progress": "Es wird gearbeitet",
  "forum": "EV Forum",
  "topic": "Thema",
  "topics": "Themen",
  "title": "Titel",
  "created": "Erstellt",
  "from": "von",
  "answers": "Antworten",
  "post-answer": "Antwort posten",
  "edit-answer": "Antwort editieren",
  "new-topic": "Neues Thema",
  "edit-topic": "Thema editieren",
  "create-new-topic": "Neues Thema erstellen",
  "last-answer": "Letzte Antwort",
  "view-all": "Alle ansehen",
  "attachments": "Anhänge",
  "newest-topics": "Neueste Themen",
  "privacy-policy": "Datenschutz",
  "new-files": "Neue Dateien",
  "existing-files": "Bestehende Dateien",
  "dialog": {
    "are-you-sure": "Sind Sie sicher?",
    "an-unexpected-error-occured": "Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es später noch einmal",
    "do-you-really-want-the-user-to": "Wollen Sie wirklich den Benutzer",
    "do-you-really-want-the-invitation-to": "Wollen Sie wirklich die Einladung",
    "delete": "löschen",
    "delete-user": "Benutzer löschen",
    "delete-topic": "Thema löschen",
    "delete-topic-answer": "Antwort löschen",
    "remove-user": "Benutzer entfernen",
    "remove-trainer": "Prüfer entfernen",
    "remove-from-company": "von der Firma entfernen?",
    "remove-from-certification-authority": "von der Zulassungsstelle entfernen?",
    "create-user": "Benutzer anlegen",
    "the-most-important-information": "Die wichtigsten Informationen",
    "create-company": "Firma anlegen",
    "the-most-important-information-about-the-company": "Die wichtigsten Informationen der Firma",
    "create-company-vehicle": "Firmen Fahrzeug anlegen",
    "the-most-important-information-about-the-vehicle": "Die wichtigsten Informationen des Fahrzeugs",
    "create-report": "Prüfbericht anlegen",
    "where-is-the-company-located": "Wo befindet sich die Firma?",
    "where-is-the-certification-authority-located": "Wo befindet sich die Zulassungsstelle?",
    "whats-the-role-of-the-person": "Was hat die Person für eine Funktion?",
    "where-does-the-person-live": "Wo wohnt die Person?",
    "further-details": "Weitere Details",
    "register-successfull": "Die Registrierung war erfolgreich! Sie erhalten in Kürze eine E-Mail mit ihrem Passwort.",
    "great-you-will-receive-an-email": "Sie werden in Kürze eine Email erhalten, mit der Sie ihr Passwort zurücksetzen können",
    "this-should-not-take-longer-than": "Das sollte nicht länger als 5 Minuten dauern. Falls doch, sehen Sie bitte auch in ihrem Spam-Ordner nach.",
    "i-agree": "Ich stimme den",
    "to": "zu",
    "terms-and-conditions": "Allgemeinen Geschäftsbedingungen",
    "hereby-i-agree": "Hiermit stimme ich zu, dass die Evalus GmbH meine persönlichen Daten zum Zweck der Ausstellung einer EVAL-Karte und zur Benutzerregistrierung auf my.evalcard.com verwenden darf.",
    "further-i-agree-to": "Weiters stimme ich zu, dass die Evalus GmbH mich via E-Mail Newsletter, über Neuigkeiten im Hochvoltbereich (neue Trainings, Produktneuheiten oder Veranstaltungen), bis auf Widerruf informieren darf.",
    "allowed-to-be-used": " (.de bzw. .com) verwendet werden darf.",
    "refusal-is-allways": "Ein Widerruf ist jederzeit per Mail an",
    "possible": "möglich.",
    "the-e-val-card-is-the": "Die EVAL – Karte ist der Qualifikationsnachweis im Checkkartenformat. Die Abkürzung EVAL steht für Electric Vehicle Access License",
    "complete-user-data": "Benutzerdaten vervollständigen",
    "complete-user-data-info": "Um Sie bestmöglichst unterstützen zu können und Ihnen alle Funktionen zur Verfügung stellen zu können, benötigen wir von Ihnen ein vollständig ausgefülltes Benutzerprofil",
    "your-password-reset-is-being-processed": "Ihr Passwort wird in diesem Moment zurückgesetzt. Falls das länger als ein paar Minuten dauert, versuchen Sie es erneut.",
    "your-password-has-been-sent": "Ihr Passwort wurde erfolgreich zurückgesetzt. Sie sollten innerhalb weniger Minuten eine E-Mail mit Ihrem neuen Passwort erhalten.",
    "if-it-did-not-work": "Sehen Sie auch in ihrem Spam-Ordner nach. Sollten Sie nicht innerhalb von 5-10 Minuten ihr neues Passwort erhalten haben, versuchen Sie es erneut.",
    "user-not-authorized": "Benutzername und/oder Passwort falsch",
    "user-not-activated": "Dieser Benutzer ist noch nicht aktiviert. Verwenden Sie den Link in der Aktivierungs-Email",
    "user-or-password-wrong": "Benutzername oder Passwort ist falsch",
    "users-selected": "Benutzer ausgewählt",
    "user-profile-update-successfull": "Benutzerprofil wurde erfolgreich gespeichert",
    "user-profile-update-failed": "Benutzerprofil konnte nicht gespeichert werden. Bitte versuchen Sie es später noch einmal.",
    "do-you-really-want-to-leave-the-company": "Wollen Sie wirklich die Firma verlassen?",
    "in-order-to-accept-a-company-invitation": "Um eine Firmeneinladung annehmen zu können, müssen Sie bereits registriert und eingeloggt sein",
    "do-you-really-want-to-join-the-company": "Sind Sie sicher, dass Sie der Firma beitreten wollen?",
    "if-you-are-already-in-a-company-you-will-be-removed": "Sollten Sie bereits in einer Firma sein, werden Sie aus dieser entfernt",
    "you-are-not-a-member-of-a-company": "Sie sind zurzeit noch keiner Firma zugeteilt. Sobald Sie Mitglied einer Firma sind, können Sie diese Seite verwenden.",
    "you-are-not-a-member-of-a-certification-authority": "Sie sind zurzeit noch keiner Zulassungsstelle zugeteilt",
    "do-you-really-want-the-company-to": "Wollen Sie wirklich die Firma",
    "company-created-successfully": "Firma wurde erfolgreich erstellt",
    "company-already-exists": "Eine Firma mit diesem Namen bzw. Email existiert bereits. Deswegen konnte die Firma nicht erstellt werden",
    "vehicle-save-successfully": "Fahrzeug wurde erfolgreich gespeichert",
    "vehicle-already-exists": "Fahrzeug mit dieser VIN und Besitzer existiert bereits",
    "vehicle-accepted": "Fahrzeug wurde ins System übernommen",
    "vehicle-manufacturer-accepted": "Fahrzeughersteller wurde ins System übernommen",
    "report-save-successfully": "Prüfbericht wurde erfolgreich gespeichert",
    "report-already-exists": "Ein Prüfbericht mit diesem Namen, Typ existiert bereits in dieser Firma.",
    "create-company-failed": "Firma konnte nicht erstellt werden. Bitte versuchen Sie es später noch einmal.",
    "save-user-success": "Benutzer wurde erfolgreich gespeichert",
    "user-already-exists": "Ein Benutzer mit dieser E-mail oder Namen existiert bereits.",
    "save-company-success": "Firma wurde erfolgreich gespeichert",
    "save-company-failed": "Firma konnte nicht gespeichert werden. Bitte versuchen Sie es später noch einmal.",
    "save-changes-success": "Änderungen wurden erfolgreich gespeichert",
    "do-you-really-want-the-vehicle-to": "Wollen Sie wirklich das Fahrzeug",
    "do-you-really-want-the-report-to": "Wollen Sie wirklich den Testbericht",
    "do-you-really-want-the-certificate-to": "Wollen Sie wirklich das Zertifikat",
    "do-you-really-want-the-vehicle-manufacturer-to": "Wollen Sie wirklich den Fahrzeughersteller",
    "save-vehicle-manufacturer-success": "Fahrzeughersteller wurde erfolgreich gespeichert",
    "save-vehicle-manufacturer-error": "Fahrzeughersteller konnte nicht gespeichert werden",
    "save-vehicle-success": "Fahrzeug wurde erfolgreich gespeichert",
    "save-vehicle-error": "Fahrzeug konnte nicht gespeicher werden",
    "who-works-in-the-company": "Wer arbeitet bei der Firma?",
    "who-works-in-the-certification-authority": "Wer arbeitet bei der Zulassungsstelle?",
    "your-company-has-been-created-check-your-emails": "Deine Firma wurde erstellt! Du solltest in Kürze eine Email erhalten mit der du deine Firma aktivieren kannst! Siehe ggf. auch in deinem Spam-Ordner nach.",
    "if-you-havent-received-an-email-yet": "Wenn Sie innerhalb von 5 Minuten noch keine Email erhalten haben, können Sie diese hier noch einmal anfordern.",
    "if-this-did-not-work": "Falls das noch immer nicht funktioniert hat, können Sie auch Ihre Firma verlassen und eine Neue anlegen.",
    "is-already-in-a-company": "ist bereits in einer Firma",
    "invite-user-already-exists-in-company": "Benutzer mit dieser Email ist bereits in der Firma",
    "invite-user-already-exists-in-other-company": "Benutzer mit dieser Email ist bereits in einer anderen Firma",
    "create-certification-authority": "Zertifizierungsstelle erstellen",
    "certification-authority-save-successfully": "Zertifizierungsstelle wurde erfolgreich gespeichert",
    "certification-authority-already-exists": "Eine Zertifizierungsstelle mit dieser E-mail oder Namen existiert bereits.",
    "certificate-already-exists": "Eine Zertifizierung mit diesem Namen existiert bereits für diesen Benutzer.",
    "certificate-save-successfully": "Zertifizierung wurde erfolgreich gespeichert",
    "do-you-really-want-the-certification-authority-to": "Wollen Sie wirklich die Zulassungsstelle",
    "create-certificate": "Zertifikat erstellen",
    "create-certificate-template": "Zertifikats Vorlage erstellen",
    "who-gets-the-certificate": "An wen soll das Zertifikat ausgestellt werden",
    "user-with-this-eval-id-was-not-found": "Benutzer mit dieser Eval-ID wurde nicht gefunden",
    "you-can-only-add-reports-if-you-have-company-vehicles": "Sie können einen Bericht nur erstellen, wenn Sie Firmenfahrzeuge besitzen",
    "you-can-only-input-eu-numbers": "Nur Zahlen im EU-Format möglich",
    "you-dont-have-the-rights": "Keine Berechtigung für diesen Bereich",
    "certificate-template-save-success": "Zertifikats-Vorlage wurde erfolgreich gespeichert",
    "certificate-template-sort-save-success": "Sortierung der Zertifikats-Vorlagen wurde erfolgreich gespeichert",
    "your-certification-authority-does-not-have-templates": "Bitte zuerst eine Zertifikatsvorlage erstellen um Zertifikate ausstellen zu können",
    "this-company-is-not-yet-activated": "Diese Firma wurde noch nicht aktiviert. Der Aktivierungslink wurde an die E-Mail Adresse der Firma geschickt",
    "welcome-to-the-dashboard": "Willkommen auf der Startseite! Sie sind zurzeit noch keiner Firma zugeteilt. Sobald Sie Mitglied einer Firma sind, können Sie hier eine Übersicht ihrer Protokolle sehen",
    "valid-certificates": "Gültige Zertifikate",
    "certificates-last-30-days": "Zertifikate - letzten 30 Tage",
    "reports-last-30-days": "Prüfberichte - letzten 30 Tage",
    "no-data-available": "Noch keine Daten vorhanden",
    "this-manufacturer-does-not-exist": "Dieser Hersteller ist noch nicht im System. Er wird für Sie automatisch angelegt.",
    "this-vehicle-does-not-exist": "Dieses Fahrzeug ist noch nicht im System. Er wird für Sie automatisch angelegt.",
    "required-to-complete-registration": "Um die Registrierung abschließen zu können, ist eine Zustimmung erforderlich",
    "username-is-already-used": "Dieser Benutzername ist bereits vergeben",
    "email-is-already-used": "Diese Email ist bereits vergeben",
    "enter-valid-birthday-date": "Bitte gib ein gültiges Geburtsdatum ein",
    "enter-valid-eu-number-format": "Bitte verwenden Sie nur Zahlen im EU-Format",
    "only-use-numbers-with-or": "Bitte verwenden Sie nur Zahlen und '-' oder '/'",
    "this-user-is-already-in-this-certification-authority": "Dieser Benutzer ist bereits in dieser Zertifizierungsstelle",
    "this-user-is-already-selected": "Dieser Benutzer ist bereits ausgewählt",
    "this-user-is-already-in-a-company": "Dieser Benutzer ist bereits in einer Firma",
    "the-document-could-not-be-loaded": "Das Dokument konnte nicht geladen werden",
    "": "",
    "continue": "Weiter",
    "finish": "Fertig"
  },
  "tooltip": {
    "csv-export": "CSV-Export",
    "add-user": "Benutzer hinzufügen",
    "invite-user": "Benutzer einladen",
    "delete-user": "Benutzer löschen",
    "remove-user": "Benutzer entfernen",
    "edit-user": "Benutzer editieren",
    "view-user": "Benutzer ansehen",
    "send-invitation-again": "Einladung erneut senden",
    "send-activation-again": "Aktivierungslink erneut senden",
    "admin-cant-leave-company": "Als EvalBeauftragter können Sie die Firma nicht verlassen",
    "leave-company": "Firma verlassen",
    "add-company": "Firma hinzufügen",
    "edit-company": "Firma bearbeiten",
    "delete-company": "Firma löschen",
    "add-selected-users-to-company": "Ausgewählte Benutzer einer Firma hinzufügen",
    "add-vehicle": "Fahrzeug hinzufügen",
    "show-vehicle": "Fahrzeug Details",
    "edit-vehicle": "Fahrzeug bearbeiten",
    "view-vehicle": "Fahrzeug ansehen",
    "accept-vehicle": "Fahrzeug ins System übernehmen",
    "accept-vehicle-manufacturer": "Fahrzeughersteller ins System übernehmen",
    "delete-vehicle": "Fahrzeug löschen",
    "add-vehicle-manufacturer": "Fahrzeughersteller hinzufügen",
    "edit-vehicle-manufacturer": "Fahrzeughersteller bearbeiten",
    "delete-vehicle-manufacturer": "Fahrzeughersteller löschen",
    "vehicle-details-missing": "Fahrzeug Details fehlen",
    "remove-invitation": "Einladung löschen",
    "send-missing-activations": "Fehlende Aktivierungs-Emails senden",
    "create-report": "Prüfbericht erstellen",
    "select-for-report": "Für Prüfbericht auswählen",
    "add-question": "Feld hinzufügen",
    "remove-question": "Feld entfernen",
    "create-certificates": "Zertifikate erstellen",
    "view-certificate": "Zertifikat ansehen",
    "delete-certificate": "Zertifikat löschen",
    "view-report": "Prüfbericht ansehen",
    "delete-report": "Prüfbericht löschen",
    "no-pdf-found": "Es wurde kein PDF-Dokument gefunden",
    "add-report": "Prüfbericht hinzufügen",
    "add-certificate": "Zertifikat erstellen",
    "no-certificate-to-create-report": "Sie haben kein passendes Zertifikat",
    "add-certification-authority": "Zertifizierungsstelle erstellen",
    "edit-certification-authority": "Zertifizierungsstelle bearbeiten",
    "delete-certification-authority": "Zertifizierungsstelle löschen",
    "add-certificate-template": "Zertifizierungsvorlage erstellen",
    "edit-certificate-template": "Zertifizierungsvorlage bearbeiten",
    "delete-certificate-template": "Zertifizierungsvorlage löschen",
    "view-certificate-training-data": "Trainingsunterlagen ansehen",
    "save-sorting": "Sortierung speichern",
    "mark-user": "Benutzer markieren",
    "open-topic": "Thema ansehen",
    "edit-topic": "Thema editieren",
    "delete-topic": "Thema löschen",
    "edit-topic-answer": "Antwort editieren",
    "delete-topic-answer": "Antwort löschen",
    "select-all-users": "Alle Benutzer markieren"
  },
  "phone": "Telefonnummer",
  "address": "Adresse",
  "birthday": "Geburtsdatum",
  "profile-picture": "Profilbild",
  "userprofile": {
    "edit-profile": "Profil editieren",
    "public-profile": "Öffentliches Profil",
    "following-information": "Folgende Informationen werden in deinem öffentlichen Profil angezeigt",
    "notification-settings": "Sollte deine Firma keine Benachrichtigungen senden, hat diese Einstellung keine Auswirkungen"
  },
  "training-director": "Schulungsleiter",
  "participated-the-training": "hat an der Ausbildung ",
  "result-protocol": "Ergebnisprotokoll",
  "measurements": "Messwerte",
  "freischaltung": "Freischaltung",
  "isolationswiderstand": "Isolationswiderstand",
  "potentialausgleich": "Potentialausgleich",
  "disconnection": "Freischaltung",
  "isolation-resistance": "Isolationswiderstand",
  "potential-equalization": "Potentialausgleich",
  "r": {
    "measuring-instrument-functionality": "Messgerätefunktion",
    "measuring-instrument-functionality-tested": "Messgerätefunktion",
    "positive-test-done": "Positivtest durchgeführt",
    "voltage-between-hv-plus-and-chassis": "Spannung zwischen HV-Plus und Chassis",
    "voltage-between-hv-plus-and-hv-minus": "Spannung zwischen HV-Plus und HV-Minus",
    "voltage-between-hv-minus-and-chassis": "Spannung zwischen HV-Minus und Chassis",
    "the-tester-accepts-the-security-instructions": "Der Prüfer bestätigt die Einhaltung der Sicherheitshinweise des Herstellers",
    "measuring-instrument-tested-at-reference-source": "Messgerätefunktion an einer Referenzquelle überprüft?",
    "measuring-instrument-tested": "Messgerätefunktion überprüft?",
    "absence-of-voltage": "Spannungsfreiheit",
    "absence-of-voltage-confirmed": "Spannungsfreiheit festgestellt",
    "voltage": "Spannung (V)",
    "used-voltage": "Verwendete Spannung",
    "used-test-voltage": "Angewendete Prüfspannung",
    "isolation-standard": "Sollwert des Isolationswiderstandes",
    "resistance-per-volt": "Widerstand pro Volt",
    "hv-system-voltage": "HV-Betriebsspannung / HV-Systemspannung",
    "isolation-resistance-between-hv-plus-and-chassis": "Isolationswiderstand zwischen HV-Plus und Chassis",
    "isolation-resistance-between-hv-minus-and-chassis": "Isolationswiderstand zwischen HV-Minus und Chassis",
    "target-isolation-resistance-value": "Sollwert des Isolationswiderstandes",
    "further-measurements-if-needed": "Weitere Messung falls gewünscht",
    "test-current": "Prüfstrom (A)",
    "test-current-q": "Prüfstrom ...",
    "measuring-point": "Messpunkt",
    "measuring-point-chassis-vs": "Messpunkt Chassis gegen ...",
    "converter-housing": "Invertergehäuse",
    "further-voltage-measurements-if-needed": "Weitere Spannungsmessung falls gewünscht",
    "measurements-ok-if-smaller-than-1-v": "Messergebnis Positiv wenn gemessene Spannung kleiner als 1V",
    "measuring-instrument": "Messgerätefunktion"
  },
  'new-report-no-id': 'Neuer Prüfbericht',
  'required-field': 'Dieses Feld ist ein Pflichtfeld.',
  'fill-out-all-required-fields': 'Bitte füllen Sie alle Pflichtfelder aus!',
  'valid-email-only': 'Bitte geben Sie eine gültige E-Mail ein.',
  'certificate-notification': 'Zertifikatsbenachrichtigungen',
  'certificate-notification-company': 'Damit können Benachrichtigungen für alle Mitarbeiter deaktiviert werden.',
  'haynes-pro-vehicle-details': 'HaynesPro Fahrzeugdetails',
  'haynes-pro-no-details': 'Es sind keine Fahrzeugdetails für das gewählte Fahrzeug verfügbar.',
  'haynes-pro-hv-disconnect': 'Hochvoltsystem: spannungsfrei schalten',
  'vehicle-details-admin': 'Fahrzeug Details',
  'haynes-pro-details': 'HaynesPro Details',
  'haynes-pro-details-admin': 'HaynesPro Details',
  'haynes-pro-details-not-found': 'Leider wurde für dieses Fahrzeug nichts gefunden.',
  'haynes-pro-details-more-than-one-story': 'Es wurde mehr als eine Anleitung gefunden:'
}
